<template>
  <div :id="id" class="chart"></div>
</template>

<script>
export default {
  name: 'lineChart',
  data() {
    return {
      id: 'echarts_' + 'lineChart',
      echartObj: null,
      infoList: [],
      xData: [],
      yData: [],
      yData2: []
    }
  },
  props: ['lineList'],
  watch: {
    lineList: {
      handler(newValue, oldValue) {
        this.infoList = newValue
        this.xData = []
        this.yData = []
        this.yData2 = []
        for (let obj of newValue) {
          this.xData.push(obj.key)
          this.yData.push(obj.value)
          this.yData2.push(obj.customerCount)
        }
        
        this.toDraw()
      },
      deep: true,
    },
  },
  mounted() {
    this.infoList = this.lineList
        this.xData = []
        this.yData = []
        this.yData2 = []
        for (let obj of this.lineList) {
          this.xData.push(obj.key)
          this.yData.push(obj.value)
          this.yData2.push(obj.customerCount)
        }
        
        this.toDraw()

  },
  methods: {
    toDraw() {
      document.getElementById(this.id).style.display = 'block'
      this.echartObj = this.$echarts.init(document.getElementById(this.id))
      let option = {
        grid: {
          left: '8%',
          right: '8%',
          bottom: '10%',
          top: '18%',
          containLabel: true,
        },
        tooltip: {
          trigger: 'axis',
          formatter(params){
             return `<span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:6px;width:6px;height:6px;background-color:#3F7C53;"></span>${params[0].value}万元<br /><span style="display:inline-block;margin-right:5px;margin-bottom:2px;border-radius:6px;width:6px;height:6px;background-color:#4A90E2;"></span>${params[1].value}个`
          },
          textStyle: {
            color: '#999',
          },
          backgroundColor: '#fff',
          extraCssText: 'box-shadow: 0 0 5px rgba(0, 0, 0, 0.1);z-index:10'
        },
        xAxis: {
          type: 'category',
          data: this.xData.reverse(),
          //  axisLabel: {rotate: 30},
          splitLine: {
            lineStyle: {
              color: '#ccc',
              type: 'dotted',
            },
            show: true,
          },
          axisTick: { show: false },
          axisLine: { show: false },
          boundaryGap: false,
        },
        yAxis: {
          type: 'value',
          // name: '单位：万元',
          axisTick: { show: false },
          axisLine: { show: false },
          axisLabel: { show: false },
          splitLine: {
            lineStyle: {
              color: '#ccc',
              type: 'dotted',
            },
            show: true,
          },
        },
        legend: {
          data: ['万元'],
          left: 10,
        },
        series: [
          {
            data: this.yData.reverse(),
            type: 'line',
            color: '#3F7C53',
            symbolSize: 4,
          },
          {
            data: this.yData2.reverse(),
            type: 'line',
            color: '#4A90E2',
            symbolSize: 4,
          },
        ],
      }
      this.echartObj.setOption(option)

      var index = 0 // 播放所在下标
      let that = this
      // var showTip = setInterval(function () {
        that.echartObj.dispatchAction({
          type: 'showTip',
          seriesIndex: 0,
          dataIndex: option.series[0].data.length -1
        })
        // index++
        // if (index >= option.series[0].data.length) {
        //   index = 0
        // }
      // }, 2000)

    },
  },
}
</script>

<style lang="less" scoped>
.chart {
  height: 4rem;
  width: 100%;
}

</style>

<style lang="less">
#echarts_lineChart{
  div{
    z-index: 1;
  }
}
</style>
