<template>
  <div class="bg ssyjtj">
    <!--    全屏弹窗-->
    <van-overlay :show="show">
      <div class="dialog-show-bg ">
        <div class="dialog-show">
          <div class="dialog-show-top ">
            <div class="flex align_items_center header">
              <van-icon class="cross" name="arrow-left" color="#fff" size="20px"  @click="show = false" />
              <div class="bold title">收益测算器</div>
            </div>
            <div class="card-bg VDS-ShadowBg_home">
              <div class="flex align_items_flex-end card-bg-top">
                <div class="first-label">年日均余额</div>
                <div class="second-label">{{sliderValue}}</div>
                <div class="third-label">万元</div>
              </div>
              <div class="slider-bg">
                <van-slider v-model="sliderValue" inactive-color="#B7DBBA" active-color="#3F7C53" :min="100" :max="1000" :step="50" bar-height="5px">
                  <template #button>
                    <img :src="require('@/assets/img/common/rectangle.png')" class="slider-bg-btn">
                  </template>
                </van-slider>
                <div class="slider-bg-desc">
                  <div>100万</div>
                  <div>1000万</div>
                </div>
              </div>
              <div @click="$router.push({name:'revenueStrategy'})" class="toDetailBtn"> 看看怎么达到这个小目标</div>
            </div>
          </div>
          <div class="bottom-bg">
            <div class="bottom-bg-first-label">预计年收益</div>
            <div class="bottom-bg-second-label">
              <div class="first-value">{{sliderValue *40}}.00</div>
              <div class="second-desc">元</div>
            </div>
            <div class="bottom-bg-desc">测算收益参考双百站点业绩标准,不含奖金</div>
          </div>
        </div>
      </div>
    </van-overlay>
    <div style="background: #F7F8F9; padding-bottom: 1.5rem;">
      <div class="transacrion-title">业绩统计</div>
      <div class="transacrion-bg">
        <div class="transacrion-top">
          <performanceDataCell :left-value="'时点余额'" :isShow="true" :right-value="performance.currBalance" :right-unit="'万元'"></performanceDataCell>
          <performanceDataCell :left-value="'客户数'" :right-value="performance.cardNum" ></performanceDataCell>
          <performanceDataCell :left-value="'年日均'" :right-value="performance.annualDayAvg" :right-unit="'万元'"></performanceDataCell>
          <performanceDataCell :left-value="'月日均'" :right-value="performance.monthDayAvg" :right-unit="'万元'"></performanceDataCell>
          <performanceDataCell :left-value="'月均三年'" :right-value="performance.threeYearDeposit" :right-unit="'%'"></performanceDataCell>
          <div class="show-detail-bg">
            <div class="show-detail-title">更新时间:</div>
            <div class="show-detail-time">{{performance.statisticDate}}</div>
          </div>
        </div>
        <div @click="show = true"
             class="measuring-Instrument flex justify_content_space_between align_items_center">
          <div>
            <div class="bold measuring-Instrument-Title">收益测算器</div>
            <div class="measuring-Instrument-Desc">测算收益不代表实际收益,仅供参考</div>
          </div>
          <div class="measuring-Instrument-Btn">测一测</div>
        </div>
      </div>
      <div class="line-chart">
        <div class="flex chart-title">
          <div>时点余额趋势</div>
          <div @click="seeDetail">查看详情</div>
        </div>
        <img :src="timing" class="timing-img">
        <img :src="cusNum" class="timing-img" style="margin-left:1.6rem;width:1.26rem">
        <lineChart :lineList="lineData"></lineChart>
      </div>
      <div class="update-time">更新时间：{{performance.updateTime}}</div>
    </div>

    <!-- <div class="footer">
      <van-button slot="button" color="#3F7C53" type="info" @click="accountBook">小账本</van-button>
    </div> -->
  </div>
</template>

<script>
import { getAccountBook, getPerformance, getStationPerPeriodPerformanceList } from '@/utils/api'
import common from '@/utils/common'
import performanceDataCell from "./stationAgentsubviews/performanceDataCell";
import { Toast, Overlay } from "vant";
import lineChart from '../PS/lineChart'
import {uwStatisticAction} from "../../utils/util";
export default {
  components: {
    performanceDataCell,
    lineChart
  },
  data () {
    return {
      infoList: [],
      isLoading: false,
      loading: false,
      finished: false,
      pageIndex: 1,
      error: false,
      listHeight: null,
      pageSize: '10',
      tranType: [],
      performance: {},
      show: false,
      sliderValue: 500,
      stationNo: common.getItem('stationNo'),
      lineData: [],
      timing: require('@/assets/img/ps/timing.png'),
      cusNum: require('@/assets/img/ps/cusNum.png'),
    }
  },
  mounted () {
    this.toGetPerformance();
    this.$nextTick(()=>{
      this.togetStationPerPeriodPerformanceList()
    })
    uwStatisticAction('/mysiteZZ','站长端站点详情')
  },
  methods: {
    seeDetail() {
      this.$router.push({ name: 'performanceDetail', query: {stationNo :this.stationNo}})
    },
    togetStationPerPeriodPerformanceList () {
      getStationPerPeriodPerformanceList({
        stationNo: this.stationNo,
      }).then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.lineData = res.data.data
          }
        }
      })
    },
    showDetail () {
      this.$router.push({ name: 'performanceStatistics' })
    },
    add () {
      this.$store.dispatch('keepAliveState/addKeepAliveAction', 'addTransacrionDetails')
      this.$router.push({ name: 'addTransacrionDetails' })
    },
    toGetPerformance () {
      getPerformance(common.getItem('stationNo')).then(res => {
        if (res.code == 200) {
          if (res.data.businessCode && res.data.businessCode === 200) {
            this.performance = res.data.performance;
          }
        }
      }).catch(() => {

      })
    },
    accountBook () {
      // this.$store.commit('ADD_KEEP_ALIVE', 'accountBook')
      this.$router.push({ name: 'accountBook' })
    }
  }
}
</script>

<style lang="less" scoped>
.bg {
  background: #f7f8f9;
  height: 100%;
  .timing-img{
    width: 1.5rem;
    position: absolute;
    bottom: -0.24rem;
    left: 0.48rem;
  }
  .update-time{
    font-size: 0.24rem;
    color: #9B9B9B;
    margin-left: 0.6rem;
  }
  .line-chart{
    background: #fff;
    width: 6.9rem;
    margin: 0 0.3rem 0.6rem;
    border-radius: 0.32rem;
    position: relative;
    .chart-title{
      justify-content: space-between;
      align-items: center;
      padding: 0.36rem 0.32rem;
      div{
        &:nth-child(1){
          color: #141A22;
          font-size: 0.36rem;
        }
        &:nth-child(2){
          color: #999;
          font-size: 0.26rem;
        }
      }
    }
  }
  .transacrion-title {
    font-size: 18px;
    text-align: left;
    line-height: 46px;
    margin-left: 10px;
    font-weight: bold;
    color: #1e1e1e;
    height: 46px;
  }
  .transacrion-bg {
    margin: 0 0.2rem;
    .transacrion-top {
      border-radius: 8px;
      background: #ffffff;
      padding-top: 10px;
    }
    .show-detail-bg {
      height: 40px;
      line-height: 40px;
      background-color: white;
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      font-size: 12px;
      color: #9b9b9b;
      .show-detail-title {
        margin-left: 16px;
        float: left;
      }
      .show-detail-time {
        margin-left: 2px;
        float: left;
      }
      .show-detail-arrow {
        line-height: 50px;
        float: right;
        margin-right: 10px;
        color: #3f7c53;
        font-weight: bold;
      }
      .show-detail-btn {
        font-size: 14px;
        line-height: 50px;
        float: right;
        font-weight: bold;
        margin-right: 10px;
        color: #3f7c53;
      }
    }
    .add-bg {
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      margin-top: 0.2rem;
      height: 50px;
      color: #1e1e1e;
      .add-title {
        font-size: 18px;
        text-align: left;
        font-weight: bold;
        color: #1e1e1e;
      }
      .add-btn {
        line-height: 24px;
        font-weight: bold;
        margin-right: 10px;
        color: #3f7c53;
        font-size: 12px;
        width: 68px;
        height: 24px;
        border-radius: 12px;
        text-align: center;
        background-color: rgba(63, 124, 83, 0.1);
      }
    }
  }
  .footer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 0.88rem;
    .van-button {
      width: 100%;
    }
  }
  .dialog-show-bg {
    width: 100%;
    height: 352px;
    background-color: white;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    .dialog-show {
      .dialog-show-top {
        padding-top: 20px;
        background-color: #3f7c53;
        height: 135px;
        width: 100%;
        .header {
          height: 40px;
          margin-top: 0px;
          padding: 0px 10px;
        }
        .title {
          color: white;
          font-size: 18px;
          width: 100%;
          margin-left: -15px;
          text-align: center;
        }
      }
      .card-bg {
        color: black;
        margin-top: 10px;
        background-color: #ffffff;
        margin: 10px;
        height: 165px;
        border-radius: 18px;
        .card-bg-top {
          height: 50px;
          .first-label {
            margin-left: 0.2rem;
            color: #999999;
            font-size: 13px;
          }
          .second-label {
            font-size: 28px;
            font-weight: bold;
            margin-left: 65px;
            color: #272829;
            line-height: 28px;
          }
          .third-label {
            margin-left: 5px;
            font-size: 18px;
            color: #272829;
          }
        }
      }
      .slider-bg {
        margin-top: 20px;
        margin-left: 20px;
        margin-right: 20px;
        .slider-bg-btn {
          width: 78px;
          height: 65px;
          margin-top: 12px;
        }
        .slider-bg-desc {
          display: flex;
          color: black;
          margin-top: 16px;
          margin-left: -0.2rem;
          margin-right: -0.2rem;
          justify-content: space-between;
          font-size: 13px;
          color: #272829;
        }
      }
      .bottom-bg {
        width: 100%;
        height: 100px;
        top: 250px;
        left: 0;
        position: absolute;
        text-align: center;
        .bottom-bg-first-label {
          font-size: 12px;
          color: #272829;
        }
        .bottom-bg-second-label {
          display: flex;
          color: #ee5f15;
          justify-content: center;
          align-items: flex-end;
          margin: 10px 0;
          .first-value {
            font-weight: bold;
            font-size: 24px;
            color: #3f7c53;
            line-height: 20px;
          }
          .second-desc {
            font-size: 12px;
            color: #272829;
          }
        }
        .bottom-bg-desc {
          color: #999999;
          font-size: 12px;
        }
      }
      .toDetailBtn {
        float: right;
        color: #cccccc;
        font-size: 12px;
        margin-top: 10px;
        margin-right: 10px;
        text-decoration: underline;
      }
      .block {
        width: 6rem;
      }
    }
  }
  .measuring-Instrument {
    margin: 0.2rem 0;
    background-color: white;
    padding: 10px 10px;
    background: url("../../assets/img/siteManage/cesuan_bg.png");
    background-size: 100% 100%;
    border-radius: 4px;
    height: 58px;
    .measuring-Instrument-Title {
      font-size: 16px;
      margin-left: 5px;
      color: #ffffff;
    }
    .measuring-Instrument-Desc {
      font-size: 13px;
      margin-left: 5px;
      color: #ffffff;
    }
    .measuring-Instrument-Btn {
      width: 55px;
      height: 24px;
      font-size: 12px;
      line-height: 24px;
      color: rgb(63, 124, 83);
      text-align: center;
      background-color: white;
      border-radius: 12px;
    }
  }
  .VDS-ShadowBg_home {
    border-radius: 8px;
    box-shadow: 0px 2.5px 5px 5px rgba(0, 0, 0, 0.04);
  }
  
}
</style>
<style>
.ssyjtj .van-clearfix {
  border-radius: 8px;
}
.ssyjtj .van-list__error-text,
.van-list__finished-text,
.van-list__loading {
  background: #f7f8f9;
  font-size: 12px;
}
.ssyjtj .van-clearfix:last-child .cell-bg {
  border-bottom: 1px solid #f7f8f9;
}
.ssyjtj .van-overlay{
  z-index: 100;
}
</style>
