<template>
    <div class="cellbg flex justify_content_space_between">
        <div class="second-label"  >{{leftValue}}</div>
        <div class="fourth-label" :class="{'showInfoColor' : isShow}" >{{rightValue}}{{rightUnit}}</div>
    </div>
</template>

<script>
    export default {
        name: "performanceDataCell",
        props:['leftValue','rightValue','rightUnit','isShow']
    }
</script>

<style lang="less" scoped>
    .cellbg{
        height: 40px;
        padding:0 16px;
        font-size:14px;
        .first-label{
            margin-left: 10px;
            line-height: 40px;
        }
        .second-label{
            line-height: 40px;
        }
        .fourth-label{
            line-height: 40px;
            font-weight: bold
        }
        .showInfoColor{
            color: #3F7C53;
        }
    }

</style>
